import { get } from 'core/libs/lodash';

import { GAME_COMPARE_PATHNAME } from '../constants';

import {
  ru,
  format,
  isSameYear,
  isSameDay,
  subDays,
} from 'core/libs/date-fns';

import avatar from 'site/icons/avatar.png';

import { getFieldValue } from './forms';

import {
  JOURNAL_URL,
  GEO_PLACEHOLDER,
  DEALER_URL,
  MOTO_URL,
  LCV_URL,
} from 'site/constants';

// TODO: добавить тесты после подключения jest.

export function cardDateFormat(isoDate, { forceDisableYear } = {}) {
  const date = new Date(isoDate);
  const now = Date.now();

  let pattern = 'dd MMMM yyyy';

  if (isSameDay(date, now)) {
    pattern = 'Сегодня в HH:mm';
  } else if (isSameDay(date, subDays(now, 1))) {
    pattern = 'Вчера в HH:mm';
  } else if (isSameYear(date, now) || forceDisableYear) {
    pattern = 'dd MMMM';
  }

  return format(date, pattern, { locale: ru });
}

/**
 * Возвращает заголовок топика учитывая постраничную навигацию
 */
export function getTitle(headline, page, widgets) {
  let title = headline;

  const pageBrakes = (widgets || [])
    .filter(widget => widget.type === 'pageBreak');

  if (page > 1 && page <= pageBrakes.length + 1) {
    title = pageBrakes[page - 2].attributes.title;
  }

  return title;
}

/**
 * Формирует ссылку на рубрику
 */
export function buildRubricUrl(slug, rootSlug) {
  if ((slug === rootSlug) || !rootSlug) {
    return `${JOURNAL_URL}/${slug}`;
  }
  return `${JOURNAL_URL}/${rootSlug}/${slug}`;
}

/**
 * Формирует ссылку на тег
 */
export function buildTagUrl(slug) {
  return `${JOURNAL_URL}/label/${slug}`;
}

/**
 * Формирует ссылку на страницу дилера.
 *
 * @param {string} geo
 * @param {string} brand
 * @param {string} dealer
 * @returns {string}
 */
export function buildDealerUrl(geo, brand, dealer, isMoto) {
  return `${isMoto ? '/moto' : ''}${[DEALER_URL, geo, brand, dealer].filter(Boolean).join('/')}`;
}

/**
 * Возвращает url указанной версии аватара.
 * @param {Object} [person={}] - объект, соответствующий модели person swagger-схемы
 * @param {string} [version=original] - тип аватара, который нужно получить
 * @returns {Object} Объект аватара указанной версии или аватар-заглушка.
 */

export function getAvatar(person = {}, version = 'original') {
  const path = [
    'attributes',
    'versions',
    version,
  ];

  const defaultAvatar = {
    rel_url: '/' + avatar,
    width: 400,
    height: 400,
  };

  return get(person, path, defaultAvatar);
}

/**
 * Делаем проверку на то что пост был опубликован до переезда на LB.
 */
export function isLegacyPost(publishedAt) {
  const releaseDate = '2019-05-19';
  return new Date(releaseDate).getTime() > new Date(publishedAt).getTime();
}


export const getMetrikaProps = (suffix, infinityIndex) => ({
  click: 'mobile_click' + suffix,
  show: ['mobile_show', infinityIndex + 1].join('') + suffix,
});


export function dedupe(arr) {
  return arr.reduce((unique, item) => unique.includes(item) ? unique : [...unique, item], []);
}

/**
 * Возвращает объект, определённые ключи которого обёрнуты в `filter[]`.
 * Ключи, которые НЕ должны быть обёрнуты в `filter[]`, определяются
 * константой сайта nonFilterApiParams.
 *
 * @param {Object} rawRequestParams - объект с названиями ключей без `filter[]`.
 * @returns {Object} - объект с преобразованными ключами.
 */
export function getRequestParamsWithFilter(requestParams) {
  /**
   * TODO:
   * Наверняка можно получать этот список динамически с помощью
   * какого-нибудь метода из `core/api`.
   */
  const { nonFilterApiParams } = require('site/constants');

  return Object.keys(requestParams).reduce((result, paramName) => {
    /**
     * TODO: Убрать этот хак с боди, после задачи UCMS-4904
     */
    const fixedParamName = paramName === 'body' ? 'body_type' : paramName;
    const resultKey = ~nonFilterApiParams.indexOf(fixedParamName)
      ? fixedParamName
      : `filter[${fixedParamName}]`;

    const value = requestParams[paramName];
    /**
     * TODO: Пустые значение в мультиселектах - это пустые массивы.
     * Параметры с пустыми массивами проходят в апишку и возвращают
     * некорректные результаты.
     */
    const fixedValue = Array.isArray(value) && !value.length
      ? null
      : requestParams[paramName];

    result[resultKey] = fixedValue;
    return result;
  }, {});
}


/**
 * Преобразует строку get-параметров (location.search) в объект, оборачивая
 * имена параметров в `filter[]`.
 *
 * @param {string} locationSearch - строка get-параметров, которая будет обработана в `query-string`.
 *
 * @returns {Object} - объект с обработанными ключами, построенный из переданной строки.
 */
export function buildSearchRequestParams(locationSearch = '') {
  /**
   * TODO: передавать сюда location.search после мерджа UCMS-4904
   */
  const fixedSearch = locationSearch.replace(/body\=/g, 'body_type=');
  const { default: queryString } = require('core/libs/query-string');
  const parsedSearch = queryString.parse(fixedSearch);

  return getRequestParamsWithFilter(parsedSearch);
}


/**
 * Возвращает только сущности, у которых в связях есть сущности с заданными значениями полей.
 *
 * @param {string} relationName - имя связи, которую надо проверить.
 * @param {Object[]} entities - сущности со связями, которые надо отфильтровать.
 * @param {Object[]} parentEntities - сущности, по которым будет производиться фильтрация.
 * @param {string} [entityPath=data.id] - путь до поля в сущностях, которые надо отфильтровать.
 *                                        Передаётся в `lodash.get`.
 * @param {string} [parentPath=data.id] - путь до поля в связи, по которому будет производиться фильтрация.
 *                                        Передаётся в `lodash.get`.
 * @returns {Object[]} - массив entities или пустой массив.
 */
export function filterEntitiesByRelation(relationName, entities, parentEntities, entityPath = 'data.id', parentPath = 'id') {
  if (!relationName) return entities;

  return [].concat(entities).filter(entity => {
    if (!entity || !entity.relationships || !entity.relationships[relationName]) {
      return false;
    }

    const relatedEntity = entity.relationships[relationName];

    return [].concat(parentEntities).some(parent => get(parent, parentPath) === get(relatedEntity, entityPath));
  });
}

/**
 * Если `character` является последним символом строки,
 * заменяет его на другую строку.
 *
 * @param {*} str - строка, в которой нужно заменить последний символ.
 * @param {*} character - символ в конце строки, который нужно заменить.
 * @param {string} [replacement=''] - строка, которая будет добавлена вместо заменённого символа.
 *
 * @returns {string}
 */

export function replaceLastCharacter(str = '', character, replacement = '') {
  if (str && str.charAt(str.length - 1) === character) {
    return str.substr(0, str.length - 1) + replacement;
  }
  return str;
}


/**
 * Заменяет гео-плейсхолдер в ссылке menuItem на geoSlug.
 *
 * @param {Object} menuItem - элемент меню
 * @param {string} menuItem.link - ссылка, которая может содержать GEO_PLACEHOLDER
 * @param {*} geoSlug - geo slug, который заменит GEO_PLACEHOLDER
 */
export function processGeoLink(menuItem = {}, geoSlug = '') {
  if (typeof menuItem.link !== 'string') return menuItem;

  if (menuItem.link.indexOf(GEO_PLACEHOLDER) === -1) return menuItem;

  return {
    ...menuItem,
    link: menuItem.link.replace(GEO_PLACEHOLDER, geoSlug),
  };
}


/**
 * Исключает из объекта указанные поля.
 *
 * @param {Object} source - значения формы.
 * @param {string[]} excludeFields - имена полей, значения которых должны быть исключены.
 *
 * @returns {Object}
 */
export function excludeObjectValues(source = {}, excludeFields = []) {
  return excludeFields.reduce((acc, fieldName) => {
    delete acc[fieldName];
    return acc;
  }, { ...source });
}


/**
 * Очищает поля объекта в соответствии с переданными зависимостями.
 *
 * @param {Object} data - данные.
 * @param {Object[]} dependencies - массив зависимостей.
 *
 * @returns {Object}
 */
// TODO Выяснить необходимость
export function resolveDependenciesByRelation(data = {}, dependencies = []) {
  if (!Array.isArray(dependencies)) return data;

  return dependencies.reduce((result, { field: fieldName, updates: updaters }) => {
    const fieldValue = getFieldValue(result[fieldName]);

    // if (fieldName === 'model') debugger;
    Object.keys(updaters)
      .forEach(dependentFieldName => {
        const updater = updaters[dependentFieldName];
        result[dependentFieldName] = fieldValue ? updater(result) : null;
      });

    return result;
  }, { ...data });
}


/**
 * Проверяет, страница мото или нет
 *
 * @param {string} pathName - адрес страницы
 *
 * @returns {boolean}
 */
export function isMotoPath(pathName) {
  if (!pathName) return false;
  return pathName.indexOf(MOTO_URL) === 0;
}

export function isLcvPath(pathName) {
  if (!pathName) return false;
  return pathName.indexOf(LCV_URL) === 0;
}

export function getTypeOfPage(pathName) {
  if (isMotoPath(pathName)) {
    return 'moto';
  } else if (isLcvPath(pathName)) {
    return 'lcv';
  }
  return 'cars';
}

export function getSlugInObject(arr) {
  return arr
    .map(item => item?.attributes?.slug || '')
    .join(',');
}

export default function formatGenerations(generations) {
  if (!generations) return [];

  return [].concat(generations).map(generation => {
    const {
      attributes: {
        start_date: startDate,
        end_date: endDate,
        name,
      } = {},
    } = generation || {};

    const startYear = new Date(startDate).getFullYear()  + ' г.';
    const endYear = endDate ? new Date(endDate).getFullYear() + ' г.' : ' н.в.';

    return {
      ...generation,
      attributes: {
        ...generation.attributes,
        name: `${name} (${startYear} - ${endYear})`,
      },
    };
  });
}

/**
 * Определяем как строить URL для коммерческого транспорта.
 * @param isLcv
 * @returns {string}
 */
export function processLcvUrlPrefix(isLcv) {
  return `${isLcv ? '/lcv' : ''}`;
}

export function buidReleasePhotoUrl(params) {
  const {
    brand: brandSlug,
    model: modelSlug,
    generation: generationSlug,
    body: bodySlug,
    release: releaseSlug,
  } = params;

  const motoLinkPhoto = `/moto/${brandSlug}/${modelSlug}/${releaseSlug}/photo`;
  const carLinkPhoto = `/${brandSlug}/${modelSlug}/${generationSlug}/${bodySlug}/photo`;
  return releaseSlug ? motoLinkPhoto : carLinkPhoto;
}

export function isGameCompare(pathname) {
  return pathname.indexOf(GAME_COMPARE_PATHNAME) === 0 || pathname.indexOf('/preview' + GAME_COMPARE_PATHNAME) === 0;
}
