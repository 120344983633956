import { Fragment } from 'react';
import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import H3 from 'core/components/H3';
import Link from 'core/components/Link';
import ButtonWithIcon from 'core/components/ButtonWithIcon';
import ArrowRightSm from 'site/icons/ArrowRightSm';
import ButtonTextWrap from 'site/components/Wrappers/ButtonTextWrap';

import styles from './index.styl';
import picture from './index.png';

function MotoCatalogLink({ theme }) {
  return (
    <Fragment>
      <style jsx>{`
        .${styles.footer}
          font bold 16px/1.2 ${theme.fonts.text}
          color ${theme.colors.primary}
      `}</style>
      <H3>Мотокаталог на Quto</H3>
      <Link type='secondary' to='/moto'>
        <img width='100%' src={picture} />
        <div className={styles.footer}>
          <span className={styles.footerTitle}>Более 5000 моделей в каталоге</span>
          <ButtonWithIcon
            icon={ArrowRightSm}
            iconPosition='right'
            size='small'
          >
            <ButtonTextWrap>Погнали</ButtonTextWrap>
          </ButtonWithIcon>
        </div>
      </Link>
    </Fragment>
  );
}

MotoCatalogLink.propTypes = {
  /** @ignore */
  theme: PropTypes.object,
};

const MotoCatalogLinkWithHOCs = withTheme(MotoCatalogLink);
MotoCatalogLinkWithHOCs.displayName = 'MotoCatalogLink';

export default MotoCatalogLinkWithHOCs;
export { MotoCatalogLink as StorybookComponent };
