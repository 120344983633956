import PropTypes from 'prop-types';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import Scroller from 'core/components/Scroller';

import ThemeSwitchWithColor from 'site/components/ThemeSwitchWithColor';

import CardServiceEditorsChoiceMenu from 'site/cardsService/CardServiceEditorsChoiceMenu';

import Skeleton from './Skeleton';

import styles from './index.styl';


export default function EditorsChoice({ content }) {
  if (content.length === 0) return <Skeleton />;

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .scroller
          :global(.desktop) &
            margin-left -40px
            padding-left 40px
      `}</style>
    </scope>
  );

  return (
    <ThemeSwitchWithColor tone='dark'>
      <Scroller className={scoped.wrapClassNames('scroller')} listClassName={styles.scrollerList}>
        {content.map(item => (
          <CardServiceEditorsChoiceMenu
            key={item.attributes.slug}
            content={item}
            className={styles.slide}
          />
        ))}
      </Scroller>
      <scoped.styles />
    </ThemeSwitchWithColor>
  );
}

EditorsChoice.propTypes = {
  content: PropTypes.array,
};
