import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import styles from './index.styl';

function DropdownContent({ theme, children }) {
  const secondaryMenuAtoms = theme.controls.secondaryMenu;

  return (
    <div className={styles.dropdownContent}>
      <style jsx>{`
        .${styles.dropdownContent}
          background-color ${secondaryMenuAtoms.const.backgroundDropdown}
      `}</style>
      {children}
    </div>
  );
}

DropdownContent.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(DropdownContent);
