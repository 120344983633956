import PropTypes from 'prop-types';
import cx from 'classnames';
import { get } from 'core/libs/lodash';
import { withRouter } from 'core/libs/router';

import Link from 'core/components/Link';
import withTheme from 'core/components/theme';
import Image from 'core/components/Image';

import styles from './index.styl';

import { MOTO_URL, LCV_URL } from 'site/constants';

function CardServiceEditorsChoiceMenu(props) {
  const {
    content,
    theme,
    match: {
      params: {
        slug: locationSlug,
      },
    },
    className,
  } = props;

  if (!content) return null;

  const {
    name,
    image,
    slug,
    type,
  } = content.attributes;

  const cover = get(image, 'attributes.versions.original');
  let url = `/bestchoice/${slug}`;
  if  (type === 'lcv') {
    url = `${LCV_URL}${url}`;
  } else if (type === 'moto') {
    url = `${MOTO_URL}${url}`;
  }

  return (
    <div className={cx(
      styles.card,
      locationSlug && locationSlug !== slug && styles._transparent,
      locationSlug && locationSlug === slug && styles._active,
      className,
    )}
    >
      <style jsx>{`
        .headline
          color ${theme.colors.primary}
          font 11px/1.2 ${theme.fonts.text}
      `}</style>
      <Link to={url} type='secondary'>
        <div className={styles.image}>
          <Image
            src={cover}
            width={100}
            height={59}
          />
        </div>
        <div className='headline'>{name}</div>
      </Link>
    </div>
  );
}

CardServiceEditorsChoiceMenu.propTypes = {
  /** @ignore */
  theme: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      slug: PropTypes.string,
    }),
  }).isRequired,
  content: PropTypes.object,
  className: PropTypes.string,
};

const CardWithHOCs = withRouter(withTheme(CardServiceEditorsChoiceMenu));
CardWithHOCs.displayName = 'CardServiceEditorsChoiceMenu';

export default CardWithHOCs;
export { CardServiceEditorsChoiceMenu as StorybookComponent };
