import { denormalizeData } from 'core/utils/api';
import { getOffset } from 'core/utils/url-helper';

import { buildSearchRequestParams } from 'site/utils';

import { RELEASES_LIMIT } from 'site/constants';


export const newReleasesFetcher = (customParams = {}) => (componentProps) => {
  const props = { ...componentProps, ...customParams };

  const {
    servicesApi,
    consoleError,
  } = props;

  return servicesApi
    .getNewReleases({
      type: 'cars',
      'attributes[car_release]': 'base,image,media,seo,extended',
      include: 'car_release',
      'relations[car_innovation]': 'release',
    })
    .then(denormalizeData)
    .catch(consoleError('newReleases', []));
};

export const releasesFetcher = (customParams = {}) => (componentProps) => {
  const props = { ...componentProps, ...customParams };

  const {
    servicesApi,
    consoleError,
    match,
    location,
    isMoto,
  } = props;

  const { search } = location;
  const isLcvPage = Boolean(match.params.lcv);

  const handle = isMoto
    ? servicesApi.getMotoReleases
    : servicesApi.getReleases;

  const vehiclePrefix = isMoto ? 'moto' : 'car';

  return handle({
    ...(isLcvPage && { 'filter[car_type]': 'lcv' }),
    offset: getOffset(search, RELEASES_LIMIT),
    limit: RELEASES_LIMIT,
    with_filtered_count: true,
    [`attributes[${vehiclePrefix}_release]`]: 'base,image,stats',
    'filter[usages]': 'catalog,archive',
    ...buildSearchRequestParams(search),
  })
    .then(data => ({
      meta: data.meta,
      releases: denormalizeData(data),
    }))
    .catch(consoleError('releases', []));
};

export const releasesCountFetcher = (customParams = {}) => (componentProps) => {
  const props = { ...componentProps, ...customParams };

  const { servicesApi, consoleError, match } = props;

  const isLcvPage = Boolean(match.params.lcv);

  return servicesApi.getReleases({
    ...(isLcvPage && { 'filter[car_type]': 'lcv' }),
    only_filtered_count: true,
    'filter[usages]': 'catalog,archive',
  })
    .then(({ meta }) => meta.filtered_count)
    .catch(consoleError('releasesCount', 0));
};
