// indents
export const SIDE_INDENT = 40;
export const SIDE_INDENT_MOBILE = 15;
export const VERTICAL_INDENT = 30;
export const VERTICAL_INDENT_MOBILE = 15;

// limits
export const RUBRICS_PAGE_LIMIT = 13;
export const RELEASES_LIMIT = 10;
export const OFFERS_LIMIT = 15;
export const OFFERS_LIMIT_MOBILE = 10;

// sizes
export const LAYOUT_MAX_WIDTH = 1280;
export const LAYOUT_MAX_WIDTH_MOBILE = 600;
export const CONTENT_AREA = 860;
export const CONTENT_TEXT_AREA = 620;
export const RIGHT_COLUMN_WIDTH = 300;

export const GEO_PLACEHOLDER = ':geo';

export const FULL_FILTER_HASH = '#full_filter';

export const JOURNAL_URL = '/journal';
export const DEALER_URL = '/dealer';
export const DEALERS_URL = '/dealers';
export const MOTO_URL = '/moto';
export const LCV_URL = '/lcv';
export const CATALOG_URL = '/catalog';
export const REVIEW_URL = '/review';

export const secondaryMenu = [
  { link: `/inventory/${GEO_PLACEHOLDER}`, content: 'В продаже', section: 'inventory' },
  { link: '/catalog', content: 'Каталог', section: 'catalog' },
  { link: `/dealers/${GEO_PLACEHOLDER}`, content: 'Дилеры', section: 'dealer' },
  { link: '/review', content: 'Отзывы', section: 'review' },
  { link: '/bestchoice', content: 'Подборки', dropDown: true, section: 'bestchoice' },
];

export const secondaryMenuMoto = [
  { link: `${MOTO_URL}/inventory/${GEO_PLACEHOLDER}`, content: 'В продаже', section: 'inventory' },
  { link: MOTO_URL, content: 'Каталог', section: 'catalog' },
  { link: `${MOTO_URL}/dealers/${GEO_PLACEHOLDER}`, content: 'Дилеры', section: 'dealer' },
  { link: `${MOTO_URL}/bestchoice`, content: 'Подборки', dropDown: true, section: 'bestchoice' },
];

export const rubrics = [
  { link: '/journal', content: 'Все' },
  { link: '/journal/news', content: 'Новости' },
  { link: '/journal/praktika', content: 'Практика' },
  { link: '/journal/articles', content: 'Статьи' },
  { link: '/journal/obzory-i-testy', content: 'Обзоры и тесты' },
  { link: '/journal/istoriya', content: 'История' },
  { link: '/journal/puteshestviya', content: 'Путешествия' },
  { link: '/journal/biblioteka', content: 'Библиотека' },
  { link: '/journal/video', content: 'Видео' },
];

export const carReleasePages = [
  { suffix: '', content: 'Модификации и цены' },
  { suffix: '/photo', content: 'Фото и видео' },
  { suffix: '/colors', content: 'Цвета' },
  { suffix: '/features', content: 'Особенности' },
];

export const motoReleasePages = [
  { suffix: '', content: 'Характеристики и цены' },
  { suffix: '/photo', content: 'Фото и видео' },
];

export const mapEngine = {
  b: 'Бензин',
  d: 'Дизель',
  h: 'Гибрид',
};

export const MIN = 'min';
export const MAX = 'max';

/** Параметры запроса, которые не оборачиваются в `filter[]`. */
export const nonFilterApiParams = [
  'with_filtered_count',
  'limit',
  'offset',
  'sort',
];
/**
 * Первый элемент в массиве это дефолтное значение
 */
export const offersSortFilters = [
  {
    slug: 'date',
    title: 'дате',
  },
  {
    slug: 'price',
    title: 'цене',
  },
  {
    slug: 'year',
    title: 'году',
  },
  {
    slug: 'run',
    title: 'пробегу',
  },
  {
    slug: 'volume',
    title: 'мощности',
  },
  {
    slug: 'title',
    title: 'названию',
  },
];

export const reviewSortFilters = [
  {
    slug: 'like',
    title: 'полезности',
  },
  {
    slug: 'date',
    title: 'дате добавления',
  },
];

export const REVIEW_COMMENTS_ID_PREFIX = 'reviews_item_';

export const catalogSortFilters = [
  {
    slug: 'price_min',
    title: 'цене',
  },
  {
    slug: 'title',
    title: 'марке и модели',
  },
];

export const abilities = [
  { name: 'Заряженность', slug: 'total' },
  { name: 'Безопасность', slug: 'safety' },
  { name: 'Комфорт', slug: 'comfort' },
  { name: 'Аудио', slug: 'audio' },
  { name: 'Оснащенность', slug: 'manufacturability' },
];

export const BEST_CHOICE_LIMIT = 10;

export const PDD_ALL_QUESTIONS_COUNT = 800;
export const PDD_TICKET_QUESTIONS_COUNT = 20;
export const ERROR_MESSAGE = 'При загрузке вопросов произошла ошибка, попробуйте обновить страницу или зайти позже';
export const PDD_EXAM_MAX_MISTAKES = 2;

export const GAME_COMPARE_PATHNAME = '/journal/ratings';

export const SLIDE_WIDTH = 728;
export const SLIDE_HEIGHT = 486;
export const SLIDE_HEIGHT_MOBILE = 260;

export const OFFERS_PAGE_LIMIT = 9;

/**
 * Высота для отступа у сайдбара,
 * когда тот находится в sticky состоянии.
 */
export const CARINFOBAR_DESKTOP_HEIGHT = 75;
