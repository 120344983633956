import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';
import { compose } from 'core/libs/recompose';

import { carBrandAttributes } from 'core/utils/prop-types/model';

import { withNonPureBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';
import withPageHocs from 'core/components/withPageHocs';
import AdWrapper from 'core/components/Ad/AdWrapper';
import Page from 'core/components/Page';

import TitleCatalog from 'site/components/TitleCatalog';
import HtmlWithTheme from 'site/components/HtmlWithTheme';
import WhereToBuy from 'site/components/WhereToBuy';
import { Indent } from 'site/components/Wrappers';

import { SuperFooter } from 'site/components/Ads/desktop';
import { Listing1, ListingSpec } from 'site/components/Ads/mobile';
import EntitledBlock from 'site/components/EntitledBlock';

import modelPropTypes, {
  motoBrandAttributes,
  carReleaseAttributes,
  carModelAttributes,
  seoFullDataAttributes,
} from 'site/utils/prop-types/model';

import Releases from './Releases';
import Archive from './Archive';
import Imported from './Imported';

import dataProvider from './brandDataProvider';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';


function BrandPage(props) {
  const {
    brand,
    brandSeo,
    releases,
    archiveReleases,
    archiveGenerations,
    isMobile,
    match,
  } = props;

  const {
    params: {
      lcv: isLcv,
    },
  } = match;

  const {
    slug: brandSlug,
    name: brandName,
    seo_body: seoBody,
    seo_content: seoContent,
    seo_lcv_content: seoLcvContent,
  } = brand.attributes;

  const { title: seoTitle, description: seoDescription } = brandSeo;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const spacing = isMobile ? 30 : 50;

  const historyContent = isLcv ? seoLcvContent : seoContent;

  const unofficialReleleases = releases.filter(release => release.attributes.is_imported);
  const officialReleleases = releases.filter(release => !release.attributes.is_imported);

  return (
    <Page title={seoTitle} description={seoDescription}>
      <AdWrapper bottom={vertical}>
        <TitleCatalog
          brand={brand}
          title={`Модельный ряд ${brandName}`}
          withLink={false}
        />
        <Indent bottom={vertical} />

        <HtmlWithTheme html={seoBody} />
        <Indent bottom={vertical} />

        {officialReleleases.length > 0 && (
          <Indent bottom={spacing}>
            <Releases releases={officialReleleases} />
          </Indent>
        )}

        {unofficialReleleases.length > 0 &&
          <Indent bottom={spacing}>
            <Imported releases={unofficialReleleases} />
          </Indent>
        }

        {archiveReleases.length > 0 &&
          <Archive
            archiveReleases={archiveReleases}
            archiveGenerations={archiveGenerations}
            brandSlug={brandSlug}
          />
        }

        <Desktop><SuperFooter /></Desktop>
        <Mobile><Listing1 /></Mobile>

        <WhereToBuy brandName={brandName} />
        <Indent bottom={vertical} />

        {historyContent.length > 0 && (
          <Fragment>
            <EntitledBlock title={`История ${brandName}`}>
              <HtmlWithTheme html={historyContent} />
            </EntitledBlock>
            <Indent bottom={vertical} />
          </Fragment>
        )}

        <Mobile><ListingSpec /></Mobile>
      </AdWrapper>
    </Page>
  );
}

BrandPage.propTypes = {
  brand: modelPropTypes(PropTypes.oneOfType([
    carBrandAttributes,
    motoBrandAttributes,
  ])).isRequired,
  brandSeo: seoFullDataAttributes.isRequired,
  releases: PropTypes.arrayOf(modelPropTypes(carReleaseAttributes)).isRequired,
  archiveReleases: PropTypes.arrayOf(modelPropTypes(carModelAttributes)),
  archiveGenerations: PropTypes.arrayOf(modelPropTypes(carModelAttributes)),
  isMobile: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      lcv: PropTypes.string,
      brand: PropTypes.string.isRequired,
    }),
  }),
};

export default compose(
  withRouter,
  withPageHocs(dataProvider),
  withNonPureBreakpoint,
)(BrandPage);
