import { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import StarRatings from 'react-star-ratings';

import pluralize from 'core/utils/pluralize';

import Link from 'core/components/Link';
import Image from 'core/components/Image';

import Label from 'site/components/Label';
import Divider from 'site/components/Divider';
import cardServiceHOC from 'site/components/cardService';

import ArrowRightSm from 'site/icons/ArrowRightSm';

import getThumbnail from 'site/utils/get-thumbnail';
import modelPropTypes, { carReleaseAttributes } from 'site/utils/prop-types/model';
import styles from './index.styl';

function CardServiceModification1(props) {
  const {
    theme,
    content: {
      attributes: {
        url,
        image,
        short_name: shortName,
        name,
        reviews_count: reviewsCount,
        main_avg_rating: averageRating,
        is_new: isNew,
        modifications_count_catalog: modificationsCountCatalog,
        modifications_count: modificationsCount,
        usage,
      },
    },
    position,
    positionOffset,
    showAllModification,
    content,
    children,
  } = props;

  if (!content) return null;

  const cover = getThumbnail(image);
  const count = showAllModification ? modificationsCount : modificationsCountCatalog;
  const modificationsText = `${count} ${pluralize(count, ['модификация', 'модификации', 'модификаций'])}`;
  const reviewsText = `(${reviewsCount} ${pluralize(reviewsCount, ['отзыв', 'отзыва', 'отзывов'])})`;

  const checkOnHasCounts = Boolean(averageRating && reviewsCount);
  const isArchive = usage === 'archive';

  return (
    <div className={cx(
      'cardServiceModification1',
      checkOnHasCounts && styles.withCounts
    )}
    >
      <style jsx>{`
        .title
          color ${theme.colors.primary}
          font 700 18px/20px ${theme.fonts.text}
          transition color ${theme.animations.hover}

        .count
          color ${theme.colors.blue2}
          font 500 14px/16px ${theme.fonts.text}
          transition color ${theme.animations.hover}

          & :global([id*="!"])
            stroke ${theme.colors.blue2}

        .rating
          color ${theme.colors.primary}
          font 14px/20px ${theme.fonts.text}

        .cardServiceModification1
          &:hover
            .title
              color ${theme.colors.active1}

        .${styles.position}
          font 700 22px/1 ${theme.fonts.text}
          color ${theme.colors.hint}

        .${styles.image}
          opacity ${isArchive ? 0.5 : 1}
      `}</style>
      <Link
        type='secondary'
        to={url}
        className={styles.link}
      >
        {positionOffset && (
          <div className={styles.position}>
            {positionOffset + position}
          </div>
        )}
        <div className={cx('image', styles.image)}>
          {cover && (
            <Image
              src={cover}
              maxWidth={140}
              maxHeight={100}
            />
          )}
        </div>
        <div className={styles.content}>
          <div className={cx('title', styles.title)}>
            {isNew && <Label className={styles.label} type={1} />}
            {shortName || name}
            {isArchive && <Label className={styles.label} type={3} />}
          </div>
          {children}
        </div>
        {checkOnHasCounts && (
          <div className={cx('rating', styles.rating)}>
            {!!Number(averageRating) && (
              <Fragment>
                <StarRatings
                  rating={Number(averageRating)}
                  starRatedColor={theme.colors.blue2}
                  numberOfStars={5}
                  name='rating'
                  starDimension='16px'
                  starSpacing='2px'
                />
                <span className={styles.averageRating}>{averageRating}</span>
              </Fragment>
            )}
            {!!reviewsCount && <div className={styles.reviews}>{reviewsText}</div>}
          </div>
        )}
        {Boolean(count) && (
          <div className={cx('count', styles.count)}>
            {modificationsText}
            <ArrowRightSm className={styles.arrow} />
          </div>
        )}
      </Link>
      <Divider />
    </div>
  );
}

CardServiceModification1.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  theme: PropTypes.object,
  /** Данные для карточки, соответствующие модели `carReleaseAttributes` */
  content: modelPropTypes(carReleaseAttributes),
  /** Если указано смещение позиции, то в карточке выводится текущая позиция + смещение */
  positionOffset: PropTypes.number,
  /** Флаг, определяющий отображение модификаций в карточке */
  showAllModification: PropTypes.bool,
  /** Позиция карточки в списке */
  position: PropTypes.number,
};

const CardWithHOCs = cardServiceHOC(CardServiceModification1);
CardWithHOCs.displayName = 'CardServiceModification1';

export default CardWithHOCs;
export { CardServiceModification1 as StorybookComponent };
