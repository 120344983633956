import { Fragment, useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import skip from 'core/resolver/skip';

import { compose } from 'core/libs/recompose';
import { withRouter } from 'core/libs/router';

import withTheme from 'core/components/theme';
import { withBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';

import Scroller from 'core/components/Scroller';
import Dropdown from 'core/components/Dropdown';
import EmptyWrapper from 'core/components/EmptyWrapper';

import { denormalizeData } from 'core/utils/api';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import withGeo from 'site/components/GeoContext/withGeo';

import { getTypeOfPage, isMotoPath } from 'site/utils';
import servicesApiPropTypes from 'site/utils/prop-types/api';

import {
  SIDE_INDENT,
  SIDE_INDENT_MOBILE,
  GEO_PLACEHOLDER,
  secondaryMenu,
  secondaryMenuMoto,
} from 'site/constants';

import EditorsChoice from './EditorsChoice';
import DropdownContent from './DropdownContent';
import MenuItem from './MenuItem';

import styles from './index.styl';

const bodyOverlayScope = resolveScopedStyles(
  <scope>
    <style jsx>{`
      :global(.layoutContent):after
        content ''
        position fixed
        top 0
        bottom 0
        left 0
        right 0
        background #000
        opacity 0.5
        z-index 3
    `}</style>
  </scope>
);

function SecondaryMenu(props, context) {
  const {
    section,
    geoSlug,
    defaultGeo,
    isMobile,
    location: {
      key: locationKey,
      pathname,
    },
    theme: {
      controls: {
        secondaryMenu: secondaryMenuAtoms,
      },
    },
  } = props;

  const [isDropdownOpen, toggleDropdownOpen] = useState(false);
  const [editorsChoiceData, setEditorsChoiceData] = useState([]);
  const prevLocationKey = useRef(locationKey).current;

  const getEditorsChoice = useCallback(() => {
    if (editorsChoiceData.length > 0) return;

    const type = getTypeOfPage(pathname);

    context.servicesApi.getEditorsChoice({
      'attributes[car_editors_choice]': 'base,image',
      'filter[type]': type,
    }).then(denormalizeData).then(setEditorsChoiceData);
  }, [context.servicesApi, pathname, editorsChoiceData]);

  const onOpen = useCallback(() => {
    toggleDropdownOpen(true);
    getEditorsChoice();
  }, [getEditorsChoice]);

  const onClose = useCallback(() => {
    toggleDropdownOpen(false);
  }, []);

  useEffect(() => {
    if (locationKey !== prevLocationKey) onClose();
  }, [locationKey, prevLocationKey, onClose]);

  const isMoto = isMotoPath(pathname);
  const menu = isMoto ? secondaryMenuMoto : secondaryMenu;
  const sideIndent = isMobile ? SIDE_INDENT_MOBILE : SIDE_INDENT;
  const Menu = isMobile ? Scroller : EmptyWrapper;

  return (
    <nav
      className={styles.secondaryMenu}
      data-qa='secondary-menu'
    >
      <style jsx>{`
        .${styles.secondaryMenu}
          background ${secondaryMenuAtoms.const.background}

        .${styles.itemsList}
          padding 0 ${sideIndent}px
      `}</style>
      <Menu>
        <div className={styles.itemsList}>
          {menu.map((item) => {
            const isGeoLink = item.link.indexOf(GEO_PLACEHOLDER) > -1;
            const slug = geoSlug ? geoSlug : defaultGeo.attributes.url;
            const link = isGeoLink ? item.link.replace(GEO_PLACEHOLDER, slug) : item.link;
            const isActive = section === item.section;

            if (item.dropDown) {
              return (
                <Fragment key={link}>
                  <Desktop>
                    <Dropdown
                      onOpenDelay={300}
                      closeAfterUrlChanged
                      showIcon={false}
                      contentPadding='0'
                      className={styles.dropdown}
                      anchor={(
                        <MenuItem
                          isActive={isActive}
                          isDropdownOpen={isDropdownOpen}
                        >
                          {item.content}
                        </MenuItem>
                      )}
                      trigger='hover'
                      onClose={onClose}
                      onOpen={onOpen}
                    >
                      <DropdownContent>
                        <EditorsChoice content={editorsChoiceData} />
                      </DropdownContent>
                    </Dropdown>
                  </Desktop>
                  <Mobile>
                    <MenuItem
                      isActive={isActive}
                      isDropdownOpen={isDropdownOpen}
                      handleClick={isDropdownOpen ? onClose : onOpen}
                    >
                      {item.content}
                    </MenuItem>
                  </Mobile>
                </Fragment>
              );
            }

            return (
              <MenuItem
                key={link}
                link={link}
                isActive={isActive}
              >
                {item.content}
              </MenuItem>
            );
          })}
        </div>
      </Menu>
      {isDropdownOpen && <bodyOverlayScope.styles />}
      {isDropdownOpen && (
        <Mobile>
          <DropdownContent>
            <EditorsChoice content={editorsChoiceData} />
          </DropdownContent>
        </Mobile>
      )}
    </nav>
  );
}

SecondaryMenu.contextTypes = {
  servicesApi: servicesApiPropTypes(),
};

SecondaryMenu.propTypes = {
  /** Имя раздела в меню, который будет подсвечиваться как активный */
  section: PropTypes.string,

  /** @ignore */
  geoSlug: PropTypes.string,

  /** @ignore */
  defaultGeo: PropTypes.object,

  /** @ignore */
  isMobile: PropTypes.bool,

  /** @ignore */
  location: PropTypes.shape({
    key: PropTypes.string,
    pathname: PropTypes.string.isRequired,
  }),

  /** @ignore */
  theme: PropTypes.object,
};

const SecondaryMenuWithHOCs = compose(
  withRouter,
  withTheme,
  withBreakpoint,
  withGeo,
  skip,
)(SecondaryMenu);

SecondaryMenuWithHOCs.displayName = 'SecondaryMenu';

export default SecondaryMenuWithHOCs;
export { SecondaryMenu as StorybookComponent };
