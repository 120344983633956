import PropTypes from 'prop-types';
import { Helmet } from 'core/libs/helmet';

import { withNonPureBreakpoint } from 'core/components/breakpoint';
import withPageHocs from 'core/components/withPageHocs';

import { carBrandAttributes } from 'core/utils/prop-types/model';

import PageLayoutService from 'site/components/PageLayoutService';
import withGeoRedirect from 'site/components/GeoContext/withGeoRedirect';

import modelPropTypes, {
  seoDataAttributes,
  geoAttributes,
  dealerNetworkAttributes,
  dealerAttributes,
} from 'site/utils/prop-types/model';
import relationshipsPropTypes from 'site/utils/prop-types/relationships';

import {
  SIDE_INDENT,
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
  SIDE_INDENT_MOBILE,
} from 'site/constants';

import SearchResults from './SearchResults';
import SeoBlock from './SeoBlock';

import dataProvider from './dealersDataProvider';


function DealersPage({ dealersSeo, dealers, isMobile }) {
  const { title, description, text } = dealersSeo;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const horizontal = isMobile ? SIDE_INDENT_MOBILE : SIDE_INDENT;

  return (
    <PageLayoutService fullWidthContent>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={description} />
      </Helmet>
      <SearchResults dealers={dealers} />
      {!!text && <SeoBlock
        {...dealersSeo}
        indents={{
          top: vertical,
          right: horizontal,
          bottom: vertical,
          left: horizontal,
        }}
      /> }
    </PageLayoutService>
  );
}

DealersPage.propTypes = {
  /** Данные для блока SEO. */
  dealersSeo: seoDataAttributes,

  /** Список дилеров для отображения. */
  dealers: PropTypes.arrayOf(modelPropTypes(
    dealerAttributes,
    relationshipsPropTypes({
      geo: modelPropTypes(geoAttributes),
      car_brands: modelPropTypes(carBrandAttributes),
      dealer_network: modelPropTypes(dealerNetworkAttributes),
    }),
  )),

  /** @ignore */
  isDesktop: PropTypes.bool,

  /** @ignore */
  isMobile: PropTypes.bool,

  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
    url: PropTypes.isRequired,
  }).isRequired,
};

export default withGeoRedirect(withPageHocs(dataProvider)(withNonPureBreakpoint(DealersPage)));
