import { Fragment,  useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import { withRouter } from 'core/libs/router';

import { Section, Block } from 'core/components/Grid';
import Position from 'core/components/GridPosition';
import H4 from 'core/components/H4';
import H3 from 'core/components/H3';
import Loader from 'core/components/Loader';

import { Indent } from 'site/components/Wrappers';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

import modelPropTypes, {
  carModelAttributes,
} from 'site/utils/prop-types/model';
import { isMotoPath } from 'site/utils';

import Search from './Search';
import { getMoto, getCars } from './utils';
import Releases from './Releases';


function Archive(props) {
  const {
    isMobile,
    archiveReleases,
    archiveGenerations,
    brandSlug,
    location,
  } = props;

  const isMoto = isMotoPath(location.pathname);
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const archiveContent = isMoto ? getMoto(archiveReleases) : getCars(archiveReleases, archiveGenerations);
  const [searchContent, updateSearchContent] = useState(archiveContent);
  const [showSpinner, toggleSpinner] = useState(false);

  useEffect(() => {
    updateSearchContent(archiveContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandSlug]);

  const updateContentBySearchFilter = useCallback(newValue => {
    if (newValue === '') {
      updateSearchContent(archiveContent);
    } else {
      const filteredContent = archiveContent.filter(archive => {
        return archive.attributes.short_name.toLowerCase().includes(newValue.toLowerCase());
      });
      updateSearchContent(filteredContent);
    }
  }, [archiveContent]);

  return (
    <Fragment>
      <Section>
        <Block>
          <H3>Модели, выпускавшиеся ранее</H3>
        </Block>
        <Block desktop={4} mobile={0}>
          <Search
            onSearch={updateContentBySearchFilter}
            toggleSpinner={toggleSpinner}
          />
        </Block>
      </Section>
      <Indent top={vertical} />
      {showSpinner && <Loader spinnerWidth={32} spinnerHeight={32} />}
      {searchContent.length > 0 && !showSpinner && (
        <Releases releases={searchContent} isMoto={isMoto} />
      )}
      {searchContent.length === 0 && !showSpinner && (
        <Position center><H4>Модели не найдены</H4></Position>
      )}
      <Indent bottom={vertical} />
    </Fragment>
  );
}

Archive.propTypes = {
  isMobile: PropTypes.bool,
  location: PropTypes.object,
  archiveReleases: PropTypes.arrayOf(modelPropTypes(carModelAttributes)),
  archiveGenerations: PropTypes.arrayOf(modelPropTypes(carModelAttributes)),
  brandSlug: PropTypes.string,
};

export default withBreakpoint(withRouter(Archive));
