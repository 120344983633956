import PropTypes from 'prop-types';

import queryString from 'core/libs/query-string';

import withPageHocs from 'core/components/withPageHocs';
import bindProps from 'core/components/bindProps';
import { withBreakpoint } from 'core/components/breakpoint';
import H3 from 'core/components/H3';
import Page from 'core/components/Page';
import Feed from 'core/components/Feed';
import ListPage from 'core/components/ListPage';

import PageLayoutService from 'site/components/PageLayoutService';
import { Indent } from 'site/components/Wrappers';
import BestChoice from 'site/components/BestChoice';
import NothingFound from 'site/components/NothingFound';
import Comparison from 'site/components/Comparison';

import modelPropTypes, { carEditorsChoiceAttributes } from 'site/utils/prop-types/model';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE, BEST_CHOICE_LIMIT } from 'site/constants';

import CardModification1WithPrice from 'site/cardsService/CardServiceModification1/cards/CardModification1WithPrice';
import CardModification2WithPrice from 'site/cardsService/CardServiceModification2/cards/CardModification2WithPrice';

import dataProvider from './bestChoiceDataProvider';


function getPositionOffset(page) {
  return (page - 1) * BEST_CHOICE_LIMIT + 1;
}

function BestChoicePage(props) {
  const {
    isMobile,
    selections,
    bestChoice: {
      attributes: {
        name,
        description,
        seo_title: seoTitle,
        seo_head: seoHead,
        type,
      },
    },
    editorsChoice,
    comparisons,
    location: {
      search,
    },
  } = props;
  const positionOffset = getPositionOffset(queryString.parse(search).page || 1);

  const CardServiceModification1WithAllModifications = bindProps({
    showAllModification: true,
    positionOffset,
  })(CardModification1WithPrice);

  const CardServiceModification2WithAllModifications = bindProps({
    showAllModification: true,
  })(CardModification2WithPrice);

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <PageLayoutService>
      {selections.data.length ? (
        <ListPage
          title={seoTitle}
          rawData={selections}
          limit={BEST_CHOICE_LIMIT}
        >
          {({ content }) => {
            return (
              <Indent bottom={vertical}>
                <H3>{seoHead || name}</H3>
                {description && <p>{description}</p>}
                <Indent bottom={vertical} />
                <Feed
                  content={content}
                  card={isMobile ? CardServiceModification2WithAllModifications : CardServiceModification1WithAllModifications}
                />
              </Indent>
            );
          }}
        </ListPage>
      ) : (
        <Page title={seoTitle}>
          <NothingFound text='Ничего не найдено.' />
        </Page>
      )}
      <Indent bottom={vertical} top={vertical}>
        <Comparison content={comparisons} vehicleType={type} />
      </Indent>
      <Indent top={vertical} bottom={vertical}>
        <BestChoice content={editorsChoice} />
      </Indent>
    </PageLayoutService>
  );
}

BestChoicePage.propTypes = {
  isMobile: PropTypes.bool,
  selections: PropTypes.object,
  bestChoice: PropTypes.object,
  editorsChoice: PropTypes.arrayOf(modelPropTypes(carEditorsChoiceAttributes)),
  comparisons: PropTypes.array,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

export default withPageHocs(dataProvider)(withBreakpoint(BestChoicePage));
