import BaseApi from 'core/api/BaseApi';

import configResolver from 'site/shared-app-config';

const config = configResolver();

export default class ServicesApiClient extends BaseApi {
  constructor(options) {
    super(options);
    this.client.defaults.headers['Content-Type'] = 'application/json';

    this.client.interceptors.request.use(
      this.handleRequest,
      this.handleRequestError,
      { synchronous: true }
    );

    this.client.interceptors.response.use(
      this.getData,
      this.handleResponseError,
      { synchronous: true }
    );
  }

  handleRequest = request => {
    if (request.method === 'get' && request.params) {
      request.params = this.transformRequestParams(request.params);
    }
    return request;
  };

  getCatalogDictionaries = params => this.client({
    method: 'get',
    url: '/catalog/dictionaries/',
    params,
  });

  getCatalogSearchEntities = params => this.client({
    method: 'get',
    url: '/catalog/search-entities/',
    params,
  });

  getCatalogBaseEntities = params => this.client({
    method: 'get',
    url: '/catalog/base-entities/',
    params,
  });

  getBrands = params => this.client({
    method: 'get',
    url: '/catalog/brands/',
    params,
  });

  getModels = params => this.client({
    method: 'get',
    url: '/catalog/models/',
    params,
  });

  getGenerations = params => this.client({
    method: 'get',
    url: '/catalog/generations/',
    params,
  });

  getReleases = params => this.client({
    method: 'get',
    url: '/catalog/releases/',
    params,
  });

  getReleasesSeo = params => this.client({
    method: 'get',
    url: '/seo/car/release/',
    params,
  });

  getReleasesPhotoSeo = params => this.client({
    method: 'get',
    url: '/seo/car/release-photos/',
    params,
  });

  getReleasesFeaturesSeo = params => this.client({
    method: 'get',
    url: '/seo/car/release-features/',
    params,
  });

  getReleasesFeatureSeo = params => this.client({
    method: 'get',
    url: '/seo/car/release-feature/',
    params,
  });

  getReleasesFeatureCommentsSeo = params => this.client({
    method: 'get',
    url: '/seo/car/release-feature-comments/',
    params,
  });

  getModifications = params => this.client({
    method: 'get',
    url: '/catalog/modifications/',
    params,
  });

  getDependenciesGraph = params => this.client({
    method: 'get',
    url: '/catalog/dependencies-graph/',
    params,
  });

  getNewReleases = params => this.client({
    method: 'get',
    url: '/catalog/new_releases/',
    params,
  });

  getEditorsChoice = params => this.client({
    method: 'get',
    url: '/catalog/editors_choice/',
    params,
  });

  getCompareData = params => this.client({
    method: 'get',
    url: '/catalog/compare/',
    params,
  });

  getCompareTop = params => this.client({
    method: 'get',
    url: '/catalog/compare-top/',
    params,
  });

  getCatalogOsagoGeos = params => this.client({
    method: 'get',
    url: '/catalog/osago-geos/',
    params,
  });

  getCatalogConfigurator = params => this.client({
    method: 'get',
    url: '/catalog/configurator/',
    params,
  });

  getCatalogConfiguratorBaseData = params => this.client({
    method: 'get',
    url: '/catalog/configurator-base-data/',
    params,
  });

  getMotoDictionaries = params => this.client({
    method: 'get',
    url: '/moto/dictionaries/',
    params,
  });

  getMotoBaseEntities = params => this.client({
    method: 'get',
    url: '/moto/base-entities/',
    params,
  });

  getMotoBrands = params => this.client({
    method: 'get',
    url: '/moto/brands/',
    params,
  });

  getMotoModels = params => this.client({
    method: 'get',
    url: '/moto/models/',
    params,
  });

  getMotoYears = params => this.client({
    method: 'get',
    url: '/moto/years/',
    params,
  });

  getMotoReleases = params => this.client({
    method: 'get',
    url: '/moto/releases/',
    params,
  });

  getMotoOffersDictionaries = params => this.client({
    method: 'get',
    url: '/moto/offers/dictionaries/',
    params,
  });

  getMotoOffersFilters = params => this.client({
    method: 'get',
    url: '/moto/offers/filters/',
    params,
  });

  getMotoOffers = params => this.client({
    method: 'get',
    url: '/moto/offers/',
    params,
  });

  getMotoOffersGeos = params => this.client({
    method: 'get',
    url: '/moto/offers/geos/',
    params,
  });

  getMotoCompareData = params => this.client({
    method: 'get',
    url: '/moto/compare/',
    params,
  });

  getMotoCompareTop = params => this.client({
    method: 'get',
    url: '/moto/compare-top/',
    params,
  });

  getDealers = params => this.client({
    method: 'get',
    url: '/dealers/dealers/',
    params,
  });

  getDealersNetworks = params => this.client({
    method: 'get',
    url: '/dealers/networks/',
    params,
  });

  getDealersGeos = params => this.client({
    method: 'get',
    url: '/dealers/geos/',
    params,
  });

  getReviewsDictionaries = params => this.client({
    method: 'get',
    url: '/reviews/dictionaries/',
    params,
  });

  getReviews = params => this.client({
    method: 'get',
    url: '/reviews/',
    params,
  });

  getReviewsSeo = (match) => {
    const {
      brand,
      model,
      generation,
      body,
      reviewId,
    } = match.params;

    let url = '/seo/car-reviews/';
    if (body && reviewId && brand && model && generation) {
      url = '/seo/car-reviews/review/';
    } else if (body && brand && model && generation) {
      url = '/seo/car-reviews/release/';
    } else if (brand && model && generation) {
      url = '/seo/car-reviews/generation/';
    } else if (brand && model) {
      url = '/seo/car-reviews/model/';
    } else if (brand) {
      url = '/seo/car-reviews/brand/';
    }

    return this.client({
      method: 'get',
      url,
      params: {
        'filter[brand]': brand,
        'filter[model]': model,
        'filter[submodel]': body,
        'filter[generation]': generation,
        'filter[review]': reviewId,
      },
    });
  };

  addReview = data => this.client({
    method: 'post',
    url: '/reviews/create/',
    data,
  });

  updateReview = data => this.client({
    method: 'post',
    url: '/reviews/update/',
    data,
  });

  deleteReview = data => this.client({
    method: 'post',
    url: '/reviews/delete/',
    data,
  });

  uploadReviewImage = data => this.client({
    method: 'post',
    url: '/reviews/upload-image/',
    data,
  });

  getOffersDictionaries = params => this.client({
    method: 'get',
    url: '/offers/dictionaries/',
    params,
  });

  getOffers = params => this.client({
    method: 'get',
    url: '/offers/',
    params,
  });

  getOffersList = params => this.client({
    method: 'get',
    url: '/offers-lists/',
    params,
  });

  getBrandOffersStat = params => this.client({
    method: 'get',
    url: '/offers/stat-per-brands/',
    params,
  });

  getOffersGeos = params => this.client({
    method: 'get',
    url: '/offers/geos/',
    params,
  });

  getPddCategories = params => this.client({
    method: 'get',
    url: '/pdd/categories/',
    params,
  });

  getPddQuestions = params => this.client({
    method: 'get',
    url: '/pdd/questions/',
    params,
  });

  getUserGeos = params => this.client({
    method: 'get',
    url: '/user/geos/',
    params,
  });

  resolveGeo = params => this.client({
    method: 'get',
    url: '/geos/resolve/',
    params,
  });

  getEntityByUuid = ({ type, uuid, ...otherParams }) => this.client({
    method: 'get',
    url: `/entity-by-uuid/${type}/${uuid}/`,
    params: otherParams,
  });

  getCarBrandSeo = params => this.client({
    method: 'get',
    url: '/seo/car/brand/',
    params: params,
  });

  getCarModelSeo = params => this.client({
    method: 'get',
    url: '/seo/car/model/',
    params: params,
  });

  getCarGenerationSeo = params => this.client({
    method: 'get',
    url: '/seo/car/generation/',
    params: params,
  });

  getCarOffersGenerationSeo = params => this.client({
    method: 'get',
    url: '/seo/car-offers/generation/',
    params: params,
  });

  getMotoBrandSeo = params => this.client({
    method: 'get',
    url: '/seo/moto/brand/',
    params: params,
  });

  getMotoModelSeo = params => this.client({
    method: 'get',
    url: '/seo/moto/model/',
    params: params,
  });

  getMotoReleaseSeo = params => this.client({
    method: 'get',
    url: '/seo/moto/release/',
    params: params,
  });

  getMotoReleasePhotosSeo = params => this.client({
    method: 'get',
    url: '/seo/moto/release-photos/',
    params: params,
  });

  getCarDealersGeoSeo = params => this.client({
    method: 'get',
    url: '/seo/car-dealers/geo/',
    params: params,
  });

  getMotoDealersGeoSeo = params => this.client({
    method: 'get',
    url: '/seo/moto-dealers/geo/',
    params: params,
  });

  getCarDealersDealerSeo = params => this.client({
    method: 'get',
    url: '/seo/car-dealers/dealer/',
    params: params,
  });

  getMotoDealersDealerSeo = params => this.client({
    method: 'get',
    url: '/seo/moto-dealers/dealer/',
    params: params,
  });

  getCarDealersDealerOfBrandSeo = params => this.client({
    method: 'get',
    url: '/seo/car-dealers/dealer-of-brand/',
    params: params,
  });

  getMotoDealersDealerOfBrandSeo = params => this.client({
    method: 'get',
    url: '/seo/moto-dealers/dealer-of-brand/',
    params: params,
  });

  getCarDealersBrandSeo = params => this.client({
    method: 'get',
    url: '/seo/car-dealers/brand/',
    params: params,
  });

  getMotoDealersBrandSeo = params => this.client({
    method: 'get',
    url: '/seo/moto-dealers/brand/',
    params: params,
  });

  getCarOffersGeoSeo = params => this.client({
    method: 'get',
    url: '/seo/car-offers/geo/',
    params,
  });

  getMotoOffersGeoSeo = params => this.client({
    method: 'get',
    url: '/seo/moto-offers/geo/',
    params,
  });

  getCarOffersBrandSeo = params => this.client({
    method: 'get',
    url: '/seo/car-offers/brand/',
    params,
  });

  getMotoOffersBrandSeo = params => this.client({
    method: 'get',
    url: '/seo/moto-offers/brand/',
    params,
  });

  getCarOffersModelSeo = params => this.client({
    method: 'get',
    url: '/seo/car-offers/model/',
    params,
  });

  getMotoOffersModelSeo = params => this.client({
    method: 'get',
    url: '/seo/moto-offers/model/',
    params,
  });

  getCarOffersOfferSeo = params => this.client({
    method: 'get',
    url: '/seo/car-offers/offer/',
    params,
  });

  getMotoOffersOfferSeo = params => this.client({
    method: 'get',
    url: '/seo/moto-offers/offer/',
    params,
  });
}

export const servicesApi = new ServicesApiClient(config.servicesApi);
