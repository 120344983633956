import PropTypes from 'prop-types';

import color from 'core/libs/color';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import withTheme from 'core/components/theme';

import Link from 'core/components/Link';
import Image from 'core/components/Image';

import getThumbnail from 'site/utils/get-thumbnail';
import modelPropTypes, { carEditorsChoiceAttributes } from 'site/utils/prop-types/model';

import styles from './index.styl';

const CARD_WIDTH = 280;
const CARD_HEIGHT = 150;


function CardServiceEditorChoice(props) {
  const {
    theme,
    content: {
      attributes: {
        name,
        slug,
        image,
      },
    },
    content,
  } = props;

  if (!content) return null;

  const cover = getThumbnail(image, 'editorialChoiceCover');

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.link}
          &:before
            border 1px solid ${color(theme.colors.primary).alpha(0.1)}

          &:after
            transition opacity ${theme.animations.hover}
            box-shadow 0 1px 5px ${color(theme.colors.primary).alpha(0.2)},
              0 3px 4px ${color(theme.colors.primary).alpha(0.12)},
              0 2px 4px ${color(theme.colors.primary).alpha(0.14)}
      `}</style>
    </scope>
  );

  return (
    <div className={styles.cardServiceEditorChoice}>
      <style jsx>{`
        .${styles.image}
          max-width ${CARD_WIDTH}px
          max-height ${CARD_HEIGHT}px

        .${styles.content}
          color ${theme.colors.content}
          font 500 16px/20px ${theme.fonts.text}
          transition color ${theme.animations.hover}
      `}</style>
      <Link
        type='secondary'
        to={slug}
        className={scope.wrapClassNames(styles.link)}
      >
        {cover && (
          <div className={styles.image}>
            <Image
              src={cover}
              maxWidth={280}
              maxHeight={150}
            />
          </div>
        )}
        <div className={styles.content}>
          {name}
        </div>
      </Link>
      <scope.styles />
    </div>
  );
}

CardServiceEditorChoice.propTypes = {
  /** @ignore */
  theme: PropTypes.object,
  /** Контент карточки, соответствующий модели `carEditorsChoiceAttributes` */
  content: modelPropTypes(carEditorsChoiceAttributes),
};

const CardWithHOCs = withTheme(CardServiceEditorChoice);
CardWithHOCs.displayName = 'CardServiceEditorChoice';

export default CardWithHOCs;
export { CardServiceEditorChoice as StorybookComponent };

