import { resolveScopedStyles } from 'core/utils/styled-jsx';

import Scroller from 'core/components/Scroller';

import ThemeSwitchWithColor from 'site/components/ThemeSwitchWithColor';

import styles from './index.styl';

export default function Skeleton() {
  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .scroller
          :global(.desktop) &
            margin-left -40px
            padding-left 40px
      `}</style>
    </scope>
  );

  return (
    <ThemeSwitchWithColor tone='dark'>
      <Scroller className={scoped.wrapClassNames('scroller')} listClassName={styles.scrollerList}>
        {Array.from(Array(15)).map((empty, index) => (
          <div
            key={index}
            className={scoped.wrapClassNames('lb-skeleton', styles.slide)}
            style={{ height: 59 }}
          />
        ))}
      </Scroller>
      <scoped.styles />
    </ThemeSwitchWithColor>
  );
}
